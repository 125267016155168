import axios from 'axios'

const api = axios.create({
    //baseURL: "http://localhost:51945", //LOCAL
    //baseURL: "https://apiteste.farma4vetz.com.br" //HOMOLOG
    baseURL: "https://api.farma4vetz.com.br" //PRODUÇÃO
});

// api.interceptors.request.use((config) => {
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// })

api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error?.response?.status === 401) {
        const url = error?.config?.url;
        localStorage.removeItem('userData');
        localStorage.removeItem('sessionToken');
        if (!url?.endsWith('/v1/Auth/')) window.location.href = '/home';
    }
    return Promise.reject(error);
})

export default api;